import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, Renderer2, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';
import { ConfirmationDialogComponent } from '../profiles-moderation/confirmation-dialog/confirmation-dialog.component';
import { UpdateUserdetailsDialogComponent } from './update-userdetails-dialog/update-userdetails-dialog.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { EmailTemplateDialogComponent } from './email-template-dialog/email-template-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit, AfterViewChecked {
  @ViewChildren('accountDetails') accountDet: QueryList<ElementRef>;
  dateFlag = null;
  editable = false;
  fakeUserFlag = false;
  holdUserFlag = false;
  fakeReasons;
  HoldReasons;
  showPlanDetails = false;
  wordToNumber = { One: 1, Four: 4, Seven: 7, Ten: 10 };
  assetImagePath = '../../../assets/images/static/';
  hasApiResponse = false;
  activityLabel = [
    {
      label: 'VERY ACTIVE',
      color: '#4f03e0'
    },
    {
      label: 'ACTIVE',
      color: '#008538'
    },
    {
      label: 'SLIPPING',
      color: '#ff9800'
    },
    {
      label: 'INACTIVE',
      color: '#d50000'
    }
  ];

  profileStatus = [
    {
      heading: 'IN SCREENING',
      color: '#a369e8'
    },
    {
      heading: 'ON HOLD',
      color: '#ff9800'
    },
    {
      heading: 'SCREENED',
      color: '#008538'
    }
  ];

  activty = [
    {
      signUp: null
    },
    {
      signUpVia: null,
      signUpViaOption: ['Desktop', 'Android App', 'Ios App', 'MobileWeb']
    },
    {
      profileCompleted: null
    },
    {
      profileScreened: null
    },
    {
      lastOnline: null
    },
    {
      lastOnlineVia: null
    },
    {
      currentquestionnumber: null
    },
    {
      screeningTime: null
    }
  ];

  selectedProfileStatus = this.profileStatus[0];

  userData = {
    alias: null,
    userId: null,
    ismale: true,
    firstName: null,
    lastName: null,
    userFirstName: null,
    prospectFirstName: null,
    prospectLastName: null,
    whom: null,
    age: null,
    height: null,
    email: null,
    phone: null,
    phoneNumberTemp : null,
    countryCodeTemp : null,
    isTalkedUser: false,
    profileImage: this.assetImagePath + 'male-avatar-600x600.jpg',
    activityStatus: this.activityLabel[1],
    loginCount: null,
    regenrateMatchTimeStamp: null,
    basicLeftView: [
      {
        parameter: null,
        icon: this.assetImagePath + 'seeking-for.svg',
        toolTipText: 'Seeking For'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'time-frame.svg',
        toolTipText: 'Time Frame for Marriage'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'marital-status.svg',
        toolTipText: 'Marital Status'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'divorced-since.svg',
        toolTipText: 'Divorced Since'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'number-of-kids.svg',
        toolTipText: 'Number of Kids'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'location.svg',
        toolTipText: 'Location'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'citizen.svg',
        toolTipText: 'Citizen'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'degree.svg',
        toolTipText: 'Degree'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'income.svg',
        toolTipText: 'Income'
      },
      {
        parameter: null,
        subparameter: null,
        icon: this.assetImagePath + 'socio-economic-status.svg',
        toolTipText: 'Social Economic Status'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'religion.svg',
        toolTipText: 'Religion'
      },
      {
        parameter: null,
        icon: this.assetImagePath + 'community.svg',
        toolTipText: 'Community'
      },
    ],
    photoCounter: null,
    verifiedIconsArr: [
      {
        icon: this.assetImagePath + 'mobile-verified.svg',
        verified: false,
        toolTipText: 'Mobile Verified',
        notVerifiedicon : this.assetImagePath + 'mobilel-verified-grey.svg',
        issubmitted : false,
      },
      {
        icon: this.assetImagePath + 'email-verified.svg',
        verified: false,
        toolTipText: 'Email Verified',
        notVerifiedicon : this.assetImagePath + 'email-verified-grey.svg',
        issubmitted : false,
      },
      {
        icon: this.assetImagePath + 'video-verified-blue-icon.svg',
        verified: false,
        toolTipText: 'Video Verified',
        notVerifiedicon : this.assetImagePath + 'video-verified-grey.svg',
        issubmitted : false,
      }
      // {
      //   icon: this.assetImagePath + 'photoID-verified.svg',
      //   verified: false,
      //   toolTipText: 'PhotoID Verified',
      //   notVerifiedicon : this.assetImagePath + 'photo-id-verified-grey.svg',
      //  issubmitted : false,
      // },
      // {
      //   icon: this.assetImagePath + 'degree-verified.svg',
      //   verified: false,
      //   toolTipText: 'Degree Verified',
      //   notVerifiedicon : this.assetImagePath + 'degree-verified-grey.svg',
      //  issubmitted : false,
      // },
      // {
      //   icon: this.assetImagePath + 'employee-verified.svg',
      //   verified: false,
      //   toolTipText: 'Employee Verified',
      //   notVerifiedicon : this.assetImagePath + 'employee-id-verified-grey.svg',
      //  issubmitted : false,
      // },
    ],
    profileQuality: null,
    profileDiamond: null,
    profileUpgrade: null,
    profileACProspect: null,
    planType: null,
    remainingDays: null,
    planExpireOn: null,
    previousPlan: null,
    oneweekfreeplan: null,
    currentTimeStamp : null
  };

  languages = {
    parameter: [],
    icon: this.assetImagePath + 'language.svg',
    toolTipText: 'Languages'
  };

  planType = ['Quick Connect', 'Express Connect', 'Assisted Connect', 'Personal Touch'];

  planDuration = [1, 2, 3, 4, 5, 6];

  planTag = ['Complementary'];

  profileQuality = [1, 4, 7, 10];

  dimondProfile = ['Yes', 'No', 'Undecided'];

  // upgradeProbability = [10, 40, 70, 100];
  upgradeProbabilityValue = [
    {
      faceValue: 10,
      value: 40
    },
    {
      faceValue: 40,
      value: 60
    },
    {
      faceValue: 70,
      value: 80
    },
    {
      faceValue: 100,
      value: 100
    }
  ];

  acProspect = ['Yes', 'No', 'Maybe'];

  userPlanForm: UntypedFormGroup;
  userPlanSubmit = false;
  userProfileValueForm: UntypedFormGroup;
  userProfileValueSubmit = false;
  readMoreIndex = -1;

  timelineArr = [
    this.assetImagePath + 'call-gray.svg',
    this.assetImagePath + 'mail-gray.svg',
    this.assetImagePath + 'sms-gray.svg',
    this.assetImagePath + 'notification-gray.svg',
    this.assetImagePath + 'notes-gray.svg',
    this.assetImagePath + 'user-activity-gray.svg',
    this.assetImagePath + 'match-activity-gray.svg',
  ];

  matchesIconArr = [
    {
      icon: this.assetImagePath + 'total-matches-blue.svg',
      toolTipText: 'Browse Matches',
      count: 0,
      type: 1
    },
    {
    icon: this.assetImagePath + 'looking-for-in-patner.svg',
    toolTipText: 'Profile on Hold',
    count: 0,
    type: 10
    },
    // {
    //   icon: this.assetImagePath + 'unviewed-matches-blue.svg',
    //   toolTipText: 'Unviewed Matches',
    //   count: 0,
    //   type: 10
    // },
    {
      icon: this.assetImagePath + 'viewed-matches-blue.svg',
      toolTipText: 'Viewed Matches',
      count: 0,
      type: 2
    },
    {
      icon: this.assetImagePath + 'shortlisted-matches-blue.svg',
      toolTipText: 'Shortlisted Matches',
      count: 0,
      type: 3
    },
    {
      icon: this.assetImagePath + 'ignored-matches-blue.svg',
      toolTipText: 'Ignored Matches',
      count: 0,
      type: 4
    },
    {
      icon: this.assetImagePath + 'interest-sent-blue.svg',
      toolTipText: 'Interest Sent',
      count: 0,
      type: 5
    },
    {
      icon: this.assetImagePath + 'mutual-matches-blue.svg',
      toolTipText: 'Mutual Matches',
      count: 0,
      type: 6
    },
    {
      icon: this.assetImagePath + 'interest-received-blue.svg',
      toolTipText: 'Interest Received',
      count: 0,
      type: 7
    },
    {
      icon: this.assetImagePath + 'interest-I-declined-blue.svg',
      toolTipText: 'Interest I declined',
      count: 0,
      type: 8
    },
    {
      icon: this.assetImagePath + 'interest-they-declined-blue.svg',
      toolTipText: 'Interest they declined',
      count: 0,
      type: 9
    },
    {
      icon: this.assetImagePath + 'block-blue.svg',
      toolTipText: 'Block matches',
      count: 0,
      type: 14
    }
  ];

  tabSelection = 1;
  selMatchAction = -1;
  selImagePath;
  selTimelineIcon;
  selTimelineIconIndex;
  profileActionBtn = false;

tabIndex = [' ','ABOUT','PREFERENCES','PHOTOS','SCREENER','NOTES','EDITS']; 

  btnObj = {
    directionBtn: this.assetImagePath + 'moderation-direction-arrow.svg',
    edit: this.assetImagePath + 'edit.svg',
    info: this.assetImagePath + 'info.svg',
    tick: {
      orange: this.assetImagePath + 'tick-orange.svg',
      purple: this.assetImagePath + 'tick-purple.svg',
      white: this.assetImagePath + 'tick-white.svg',
      gray: this.assetImagePath + 'tick-gray.svg',
      red: this.assetImagePath + 'tick-red.svg',
      bgGreen: this.assetImagePath + 'tick-bg-green.svg'
    },
    cross: {
      orange: this.assetImagePath + 'cross-orange.svg',
      purple: this.assetImagePath + 'cross-purple.svg',
      white: this.assetImagePath + 'cross-white.svg',
      gray: this.assetImagePath + 'cross-gray.svg',
      green: this.assetImagePath + 'cross-green.svg',
      bgRed: this.assetImagePath + 'cross-bg-red.svg'
    }
  };


  userType;
  isReviewer = false;
  sharedData;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  horizontalPositionCopyData: MatSnackBarHorizontalPosition = 'center';
  verticalPositionCopyData: MatSnackBarVerticalPosition = 'top';
  forceLogoutCheck = null;
  // tslint:disable-next-line: max-line-length
  constructor(private renderer: Renderer2, private apiservice: ApiService, private sharedservice: SharedService, private fb: UntypedFormBuilder, private route: ActivatedRoute, private sessionstorage: SessionStorageService, private localservice: LocalStorageService, private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private cdr: ChangeDetectorRef) {
    const user = this.localservice.getFromLocal('user');
    this.sharedservice.userType.isReviewer = user.supportUserType === 'reviewer' ? true : false;
    this.sharedservice.userType.name = user?.firstname;
    this.sharedservice.isReviewer = this.sharedservice.userType.isReviewer?true :false;
    this.isReviewer = this.sharedservice.userType.isReviewer;
    this.userPlanForm = this.fb.group({
      planName: [null, [Validators.required]],
      planStartDate: ['1', [Validators.required]],
      planDuration: [null, [Validators.required]],
      planTag: [null, [Validators.required]]
    });

    // this.userProfileValueForm = this.fb.group({
    //   profileQuality: [null, [Validators.required]],
    //   profileDiamond: [null, [Validators.required]],
    //   profileUpgrade: [null, [Validators.required]],
    //   profileACProspect: [null, [Validators.required]]
    // });


  }


  ngOnInit(): void {
    this.userType = this.localservice.getFromLocal('user').supportUserType;

    if(!this.localservice.getFromLocal('checkingForWrongURL')){
      this.localservice.setToLocal('checkingForWrongURL','check');
      const afterUserIdValue = window.location.href.split('userid=');
      let tempArray : any = [];
      tempArray  = afterUserIdValue[1];
      const userId  = [];
      for(let i=0; i < tempArray.length ; i++){
        if(!isNaN(tempArray[i])){
          userId[i] = tempArray[i];
        } else {
          break;
        }
      }
      const url = '/admin/profile?userid=' + userId.join('');
      // console.log(url);
      window.location.replace(url);
    } else {
        this.localservice.removeToken('checkingForWrongURL');
    }
    this.sharedservice.tabSwitch.subscribe(res => { //Active Tab where Edit History navigate through tabIndex array.
      const activeTab= this.tabIndex.findIndex(x => x === res);
      this.tabSelection = activeTab;  
     })
    // console.log(window.location.search.substr(1).split('userid=')[1]);
    // console.log(this.sharedservice.userId);

    // this.sharedservice.userId = this.route.snapshot.queryParams.id;
    this.sharedservice.userId = Number(window.location.search.substr(1).split('userid=')[1]);
    this.sharedservice.userProfileInfo();
    this.getUserProfileData();

    // this.userProfileValueForm.valueChanges.subscribe(x => {
    //   this.profileActionBtn = true;
    // });
  }

  editAccountDetails() {
    this.editable = true;
    // console.log(this.accountDet);
    this.accountDet.forEach(element => this.renderer.addClass(element.nativeElement, 'edit-account-details'));
  }

  copyData(data){
    navigator.clipboard.writeText(data);
    const temp = 'Copied'
    this.snackBar.open(temp ,'' ,{
      horizontalPosition: this.horizontalPositionCopyData,
      verticalPosition: this.verticalPositionCopyData,
      duration: 1500,
    });
  }
  

  saveAccountDetails() {
    this.editable = false;
    const temp = ['username', 'prospectname', 'email', 'phone'];
    const param = {};
    this.accountDet.forEach((element, index) => {
      param[temp[index]] = element.nativeElement.innerText;
      this.renderer.removeClass(element.nativeElement, 'edit-account-details');
    });
    // console.log(param);
  }

  updateEmailPhoneNumber(type, email, countryCode, phoneNumber, forwhom){
    const dialog = this.dialog.open(UpdateUserdetailsDialogComponent, {
      panelClass: 'dialog-container-updateuserdetail',
      autoFocus: false,
      width: '50vw',
      height: '35vh',
      data: {infromationType : type, userId: this.sharedservice.userId, email : email, countryCode: countryCode, phoneNumber: phoneNumber, forwhom}
    });

    dialog.afterClosed().subscribe(result => {
      if(result){
        let tempString;
        if(result.email){
          tempString = 'Email changed successfully';
          this.userData.email = result.email;
          this.userData.verifiedIconsArr[1].verified = false;
        } else if(result.phoneNumber){
          tempString = 'Phone number  changed successfully';
          this.userData.phone = '+' + result.countryCode + ' ' + result.phoneNumber;
          this.userData.countryCodeTemp = '+' + result.countryCode;
          this.userData.phoneNumberTemp = result.phoneNumber;
          this.userData.verifiedIconsArr[0].verified = false;
        }

        this.snackBar.open(tempString ,'' ,{
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: 3000,
        });
      }
      
    });
    
  }

  fakeUser(isfake) {
    // this.fakeUserFlag = true;
    // const param = {
    //   isfakeprofile: true,
    //   reason: null,
    //   userid: this.sharedservice.userId
    // };
    // this.profileScreeningStatus(param);
    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      width: '30vw',
      height: '50vh',
      maxHeight: '70vh',
      data: { isfakeUser: isfake, userId: this.sharedservice.userId }
    });
    dialog.afterClosed().subscribe(res => {
      if (res.forWhom === 'marked as fake' || res.forWhom === 'undo') {
        // console.log(res);
        this.fakeReasons = res.fakeReason;
        const fakeReasonLength = this.fakeReasons?.length;
        // this.fakeReasons = this.fakeReasons?.split("\n", fakeReasonLength);
        // console.log(this.fakeReasons);
        if(this.fakeReasons){
          this.fakeReasons = this.replaceURLs(this.fakeReasons); 
        }
        
        this.fakeUserFlag = isfake ? true : false;
      }
    });

    
  }

  changeProfileStatus(type) {
    // this.selectedProfileStatus = this.profileStatus[index];
    // const param = {
    //   isscreeningdone: true,
    //   reason: null,
    //   userid: this.sharedservice.userId
    // };
    // if (index === 2) {
    //   this.profileScreeningStatus(param);
    // }
    if (type === 'ON HOLD') {
      // && this.selectedProfileStatus.heading != 'ON HOLD'  // This line was used for disable ON HOLD button when heading is ON HOLD.
      const dialog = this.dialog.open(ConfirmationDialogComponent, {
        autoFocus: false,
        maxHeight: '55vh',
        maxWidth: '30vw',
        data: { isScreeningHold: true, userId: this.sharedservice.userId }
      });
      dialog.afterClosed().subscribe(res => {
        console.log(res);
        if (res.comingfrom === 'screening is on hold') {
         // console.log(res);
          this.HoldReasons = res.holdReason;
         // console.log(this.HoldReasons);
          if(this.HoldReasons){
            this.HoldReasons = this.replaceURLs(this.HoldReasons);
          }
          this.profileStatus = [
            {
              heading: 'ON HOLD',
              color: '#ff9800'
            },
            {
              heading: 'SCREENED',
              color: '#008538'
            }
          ];
          this.selectedProfileStatus = this.profileStatus.length == 3 ? this.profileStatus[1] : this.profileStatus[0];
          this.holdUserFlag = type === 'ON HOLD' ? true : false;
        }
      });
    } else if (type === 'SCREENED') {
      const dialog = this.dialog.open(ConfirmationDialogComponent, {
        autoFocus: false,
        maxHeight: '55vh',
        maxWidth: '30vw',
        data: { isScreeningProfile: true, userId: this.sharedservice.userId }
      });
      dialog.afterClosed().subscribe(res => {
        if (res === 'screening done') {
          // console.log(res);
          this.activty[7].screeningTime = new Date();
          this.selectedProfileStatus = this.profileStatus.length == 3 ? this.profileStatus[2] : this.profileStatus[1];
          this.holdUserFlag = false;
        }
      });
    }
  }

  profileScreeningStatus(obj) {
    this.apiservice.profileScreeningStatus(obj).subscribe(res => {
      if (res) {
        // console.log(res);
      }
    });
  }

  buyPlan() {
    this.userPlanSubmit = true;
    // console.log(this.userPlanForm);
  }

  // profileValueUpdate(status) {
  //   console.log(this.userProfileValueForm);
  //   if (status) {
  //     this.userProfileValueSubmit = true;
  //     if (this.userProfileValueForm.status === 'VALID') {
  //       const userTraits = this.userProfileValueForm.value;
  //       const profileQuality = { userid: this.sharedservice.userId, value: +userTraits.profileQuality };
  //       const assistedUser = { userid: this.sharedservice.userId, value: userTraits.profileACProspect.toLowerCase() };
  //       const upgradeProbability = { userid: this.sharedservice.userId, upgrade: userTraits.profileUpgrade };
  //       const eliteUser = { userid: this.sharedservice.userId, elite: +userTraits.profileDiamond, upgrade: +userTraits.profileUpgrade };
  //       forkJoin([
  //         this.apiservice.setProfileQuality(profileQuality).pipe(tap(res => console.log(res))),
  //         this.apiservice.isAssistedConnectUser(assistedUser).pipe(tap(res => console.log(res))),
  //         this.apiservice.probabilityToUpgrade(upgradeProbability).pipe(tap(res => console.log(res))),
  //         this.apiservice.probabilityToUpgrade(eliteUser).pipe(tap(res => console.log(res))),
  //       ]).subscribe(allResults => {
  //         this.profileActionBtn = false;
  //       });
  //     }
  //   } else {
  //     this.userProfileValueForm.setValue({
  //       profileQuality: this.sharedservice.profileValue.profileQuality,
  //       profileDiamond: this.sharedservice.profileValue.profileDiamond,
  //       profileUpgrade: this.sharedservice.profileValue.profileUpgrade,
  //       profileACProspect: this.sharedservice.profileValue.profileACProspect
  //     });
  //     this.profileActionBtn = false;
  //   }
  // }


  // profile value
  profileValue(property, value) {
    const param: any = {
      userid: this.sharedservice.userId
    };
    if (property === 'quality') {
      param.value = Number(value);
      this.apiservice.setProfileQuality(param).subscribe(res => {
        // console.log(res);

      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        });
    } else if (property === 'diamond') {
      param.elite = Number(value);
      param.upgrade = 0;
      this.upgradeApi(param);
    } else if (property === 'upgrade') {
      param.upgrade = Number(value);
      this.upgradeApi(param);
    } else if (property === 'acProspect') {
      param.value = value;
      this.apiservice.isAssistedConnectUser(param).subscribe(res => {
        // console.log(res);

      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        })
    }

  }
  upgradeApi(param) {
    this.apiservice.probabilityToUpgrade(param).subscribe(res => {
      // console.log(res);
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          //  this.sharedservice.gettingServerError();
        }
      });
  }
  // is talked user
  istalkedUser(e) {
    if (e.checked) {
      this.apiservice.adminverifyphone(this.sharedservice.userId).subscribe(res => {
        this.userData.isTalkedUser = true;
        // console.log(res);

      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        })
    }

  }

  // Match Regenerate button
  matchRegenerate(){

    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#DD6B55',
      cancelButtonColor: '#C1C1C1',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const loaderDailog = this.dialog.open(LoadingScreenComponent, { disableClose: true});
        const param: any = {
          userid: this.sharedservice.userId
        };
        // console.log(this.sharedservice.userId);
        this.apiservice.generatematch(param).subscribe(res => {
          if(res){
            const currentDate = new Date();
            const timeStamp = currentDate.getTime();
            this.userData.regenrateMatchTimeStamp = timeStamp;
            // const url = '/admin/profile?userid=' + this.sharedservice.userId;
            setTimeout(() =>{
              loaderDailog.close();
            }, 500);
           // this.router.navigate(['/admin/profile?userid=' + this.sharedservice.userId]);
           
          }
        });

      }
    })
  }

  // profile deletion request
  profileDeletion() {
    // console.log('for delete profile');
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#DD6B55',
      cancelButtonColor: '#C1C1C1',
      confirmButtonText: 'Yes, delete this user!'
    }).then((result) => {
      if (result.isConfirmed) {
        const loaderDailog = this.dialog.open(LoadingScreenComponent, { disableClose: true});
        // console.log(this.sharedservice.userId);
        this.apiservice.profiledeletionaccept(this.sharedservice.userId).subscribe(res => {
          if(res){
            setTimeout(() =>{
              loaderDailog.close();
            }, 1000);
          }
          Swal.fire(
            'Deleted!',
            'This profile has been deleted.',
            'success'
          ).then((res) => {
            this.router.navigate(['/admin/activeuser']);
          })
        });

      }
    })
  }

  // force delete
  forceiblyLogUserOut() {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#DD6B55',
      cancelButtonColor: '#C1C1C1',
      text: 'Note: This will logout the user from all the session and they will not be able to re-login.',
      confirmButtonText: 'Logout from all devices',
    }).then((result) => {
      if (result.isConfirmed) {
        const loaderDailog = this.dialog.open(LoadingScreenComponent, { disableClose: true});
        // const userid = Number(this.sharedservice.userId);
        this.apiservice.kickoutuser({userid:this.sharedservice.userId}).subscribe(res => {
          if(res){
            console.log(res);
            setTimeout(() =>{
              loaderDailog.close();
            }, 1000);
          }
          Swal.fire(
            'Logged out from all devices successfully !',
          ).then((res) => {
            this.forceLogoutCheck = true;
            // this.router.navigate(['/admin/activeuser']);
          })
        });

      }
    })
  }

   // userview navigation
   navigateToUserview () {
    const url = '/userview/'+this.sharedservice.userId+'/settings/profile-settings';
    window.open(url,'_blank');
    
  }

  userMatcheAction(index, count) {
    this.tabSelection = 0;
    this.selMatchAction = index;
    const re = /blue/gi;
    this.selImagePath = this.matchesIconArr[index].icon.replace(re, 'white');
      this.sharedservice.userMatchAction.Index = this.matchesIconArr[index].type;
      this.sharedservice.userMatchAction.count = index == 1 ? 0 : count;
   // console.log(this.selMatchAction);
    
  }

  tabChange(e) {
    // console.log(e);
    if (e.index > 0) {
      this.selMatchAction = -1;
    }
  }

  filterTimeline(index) {
    const re = /gray/gi;
    this.selTimelineIconIndex = index;
    this.selTimelineIcon = this.timelineArr[index].replace(re, 'green');
  }

  getUserProfileData() {
    const params = {
      userid: this.sharedservice.userId
    };

    this.sharedservice.userProfileData.subscribe((result: any) => {
      this.forceLogoutCheck = result.userdata[0].kickoutuser;
      this.hasApiResponse = true;
      if (result) {
        this.sessionstorage.setToSession('user' + this.sharedservice.userId, result);
        this.setDataToView(result);
      } else {
        const res = this.sessionstorage.getFromSession('user' + this.sharedservice.userId);
        this.setDataToView(res);
      }
    });
  }
  ngAfterViewChecked(){
    if(this.sharedservice.videoverified){
    this.userData.verifiedIconsArr[2].verified = true;
     this.userData.verifiedIconsArr[2].issubmitted = false;
      this.cdr.detectChanges();
    }
  }


  setDataToView(result) {
    const userData = result.userdata[0];
    const userAction = result.useractions;
    let profileImage;
    if (userData.images && userData.images.imagedata && userData.profileimage) {
      userData.images.imagedata.filter(element => {
        if (element.nodeid === userData.profileimage) {
          profileImage = element.imageurl;
        }
      });
    } else {
      profileImage = this.sharedservice.getAvatarPhoto(userData.basics.ismale);
    }

    this.userData.profileImage = profileImage;
    this.userData.alias = userData.alias;
    this.userData.userId = userData.userid;
    this.userData.email = userData.email;
    this.userData.countryCodeTemp = userData.phonenumber ? ('+' + userData.countrycode) : null;
    this.userData.phoneNumberTemp = userData.phonenumber ? userData.phonenumber : null;
    this.userData.phone = userData.phonenumber ? ('+' + userData.countrycode + ' ' + userData.phonenumber) : null;
    this.userData.firstName = userData.basics.firstname ? userData.basics.firstname : null;
    this.userData.userFirstName = userData.basics.firstname ? userData.basics.firstname : userData.basics.prospectfirstname;
    this.userData.lastName = userData.basics.lastname ? userData.basics.lastname : null;
    this.userData.prospectFirstName = userData.basics.prospectfirstname ? userData.basics.prospectfirstname : null;
    this.userData.loginCount = userData.logincount;
    this.userData.prospectLastName = userData.basics.prospectlastname ? userData.basics.prospectlastname : null;
    this.userData.ismale = userData.basics.ismale;
    this.userData.isTalkedUser = ((userData.ReliabilityRating.phoneverifiedbyadmin || userData.ReliabilityRating.phoneverifiedbyreviewer || userData.ReliabilityRating.phoneverifiedbysupervisor) && userData.phonenumber) ? true : false;
    if (userData.basics.whom) {
      if (userData.basics.whom === 'Son/Daughter') {
        this.userData.whom = 'Parent';
      } else if (userData.basics.whom === 'Nephew/Niece') {
        this.userData.whom = 'Uncle/Aunt';
      } else if(userData.basics.whom === 'Self') {
        this.userData.whom = userData.basics.whom;
        // this.userData.firstName = this.userData.prospectFirstName;
        // this.userData.lastName = this.userData.prospectLastName;
        // this.userData.prospectFirstName = null;
        // this.userData.prospectLastName = null;
      } else {
        this.userData.whom = userData.basics.whom;
      }
    } else {
      this.userData.whom = null;
    }

    this.userData.regenrateMatchTimeStamp = userData.crmmatchgeneratedon;
    this.userData.age = userData.basics.age;
    const height: any = this.sharedservice.heightData.filter(ele => {
      if (ele.id === userData.appearance.height) {
        return ele.value;
      }
    });
    this.userData.height = height.length > 0 ? height[0].value : 'N.A.';
    this.userData.basicLeftView[0].parameter = userData.basics.marriagetimeframe ? userData.basics.marriagetimeframe : 'N.A.';
    this.userData.basicLeftView[1].parameter = userData.basics.opentotimeframe ? userData.basics.opentotimeframe : 'N.A.';
    this.userData.basicLeftView[2].parameter = userData.basics.maritalstatus ? userData.basics.maritalstatus : 'N.A.';
    // tslint:disable-next-line: max-line-length
    this.userData.basicLeftView[3].parameter = userData.basics.maritalsince ? userData.basics.maritalsince <= 9999999999 ? userData.basics.maritalsince * 1000 : userData.basics.maritalsince : 'Not Applicable';
    this.dateFlag = this.userData.basicLeftView[3].parameter === 'Not Applicable' ? null : this.userData.basicLeftView[3].parameter;
    this.userData.basicLeftView[4].parameter = userData.basics.childcount ? userData.basics.childcount : 'N.A.';
    // tslint:disable-next-line: max-line-length
    this.userData.basicLeftView[5].parameter = userData.basics.location && userData.basics.location.currentloc ? userData.basics.location.currentloc : 'N.A.';
    if (userData.basics.residencystatus === 'Citizen') {
      this.userData.basicLeftView[6].parameter = userData.basics.location.currentloc.split(', ')[2];
    } else {
      this.userData.basicLeftView[6].parameter = userData.basics.permanentresident ? userData.basics.permanentresident : 'N.A.';
    }
    if( userData.isscreeningpending === true){
        this.profileStatus = [
          {
            heading: 'ON HOLD',
            color: '#ff9800'
          },
          {
            heading: 'SCREENED',
            color: '#008538'
          }
        ];
    } else {
      this.profileStatus = [
        {
          heading: 'IN SCREENING',
          color: '#a369e8'
        },
        {
          heading: 'ON HOLD',
          color: '#ff9800'
        },
        {
          heading: 'SCREENED',
          color: '#008538'
        }
      ];
    }
    const degree = userData.education.colleges?.map(ele => ele.degree);
    const index = userData.flags.isscreeningdone === true ? this.profileStatus[this.profileStatus.length - 1] : (userData.isscreeningpending === true ? this.profileStatus[0] : this.profileStatus[0]);
    this.selectedProfileStatus = index;
    this.userData.basicLeftView[7].parameter = degree ? degree.toString() : 'N.A.';
    this.userData.basicLeftView[8].parameter = userData.education.income ? userData.education.income : 'N.A.';
    // tslint:disable-next-line: max-line-length
    this.userData.basicLeftView[9].parameter = userData.education.socioeconomicstatus ? userData.education.socioeconomicstatus.split(' (')[0] : 'N.A.';
    // tslint:disable-next-line: max-line-length
    this.userData.basicLeftView[9].subparameter = userData.education.socioeconomicstatus ? '(' + userData.education.socioeconomicstatus.split(' (')[1] : '';
    this.userData.basicLeftView[10].parameter = userData.background.religion ? userData.background.religion.toString() : 'N.A.';
    this.userData.basicLeftView[11].parameter = userData.background.caste ? userData.background.caste.toString() : 'N.A.';
    this.userData.verifiedIconsArr[0].verified = userData.ReliabilityRating.isphoneverified;
    this.userData.verifiedIconsArr[1].verified = userData.ReliabilityRating.isemailverified;
    // this.userData.verifiedIconsArr[2].verified = userData.trustBadge.photoid;
    // this.userData.verifiedIconsArr[3].verified = userData.trustBadge.deploma;
    // this.userData.verifiedIconsArr[4].verified = userData.trustBadge.employeeId;
    if(userData.trustBadge.videoverified){
      this.userData.verifiedIconsArr[2].verified = userData.trustBadge.videoverified;
    } else {
      this.userData.verifiedIconsArr[2].issubmitted = userData.videodata.length > 0 ? true : false;
    }

    this.userData.photoCounter = userData.ReliabilityRating.numberofimages;
    const languages = {
      language: null,
      fluency: null
    };
    userData.background.languages?.forEach(language => {
      if (language.indexOf('mother tongue') > 0) {
        languages.language = language.split(' - ')[0];
        languages.fluency = 'mother';
        this.languages.parameter.splice(0, 0, { ...languages });
      } else if (language.indexOf('complete fluency') > 0) {
        languages.language = language.split(' - ')[0];
        languages.fluency = 'complete';
        this.languages.parameter.splice(1, 0, { ...languages });
      } else {
        this.languages.parameter.push(language.split(' - ')[0]);
      }
    });

    // tslint:disable-next-line: max-line-length
    this.activty[0].signUp = userAction.signuptime ? userAction.signuptime <= 9999999999 ? userAction.signuptime * 1000 : userAction.signuptime : null;
    this.activty[1].signUpVia = this.activty[1].signUpViaOption[userData.profileAccess.signupfrom - 1];
    // tslint:disable-next-line: max-line-length
    this.activty[2].profileCompleted = userData.profilecompletedon ? userData.profilecompletedon <= 9999999999 ? userData.profilecompletedon * 1000 : userData.profilecompletedon : null;
    // tslint:disable-next-line: max-line-length
    this.activty[4].lastOnline = userAction.accesstime ? userAction.accesstime <= 9999999999 ? userAction.accesstime * 1000 : userAction.accesstime : null;
    this.activty[5].lastOnlineVia = this.activty[1].signUpViaOption[userData.profileAccess.accessedFrom - 1];
    this.activty[6].currentquestionnumber = userData.currentquesnumberapp;
    if(userData.currentquesnumberapp == -1){
      this.activty[6].currentquestionnumber = null;
    }
    this.activty[7].screeningTime = userData.screeningtimestamp;
    this.fakeUserFlag = userAction.isfakeprofile;
    this.fakeReasons = null;
    this.fakeReasons = userAction.fakeprofilereason;
    const fakeReasonLength = this.fakeReasons?.length;
    // this.fakeReasons = this.fakeReasons?.split("\n", fakeReasonLength);
    if(this.fakeReasons){
      this.fakeReasons = this.replaceURLs(this.fakeReasons);
    }

    this.HoldReasons = null;
      this.HoldReasons = userData.holdreason;
      if(this.HoldReasons && this.HoldReasons.length > 0){
        this.holdUserFlag = true;
        this.HoldReasons = this.replaceURLs(this.HoldReasons);   
      }else{
        this.holdUserFlag = false;
      }
    
     
    
    
    this.userData.oneweekfreeplan = userData.oneweekfreeplan;
    this.userData.planType = userData.plansNpacks.assisted ? 'Assisted connect' : (userData.plansNpacks.express ? 'Express connect' : (userData.plansNpacks.quick ? 'Quick connect' : ''));
    const currentDate = new Date();
    this.userData.currentTimeStamp = currentDate.getTime();
    this.userData.previousPlan = userData.plansNpacks.previousplan;
    if (userData.plansNpacks.plantimestamp) {
      const ExpiryTimeStamp = Math.ceil((userData.plansNpacks.plantimestamp - Date.now()) / 8.64e7);
      this.userData.remainingDays = this.sharedservice.planExpiryInDays(ExpiryTimeStamp);
      this.userData.planExpireOn = userData.plansNpacks.plantimestamp;
    }
    
    //  this.userProfileValueForm.setValue({
    //   profileQuality: userData.basics.ProfileQuality ? this.wordToNumber[userData.basics.ProfileQuality] : null,
    //   profileDiamond: userAction.iselite ? userAction.iselite : null,
    //   profileUpgrade: userData.basics.probabilityToUpgrade ? userData.basics.probabilityToUpgrade : null,
    //   // tslint:disable-next-line: max-line-length
    //   profileACProspect: userData.basics.probabilityToAssistedUser ? userData.basics.probabilityToAssistedUser.substring(0, 1).toUpperCase() + userData.basics.probabilityToAssistedUser.substring(1) : null
    // });
    this.userData.profileQuality = userData.basics.ProfileQuality ? this.wordToNumber[userData.basics.ProfileQuality] : null,
      this.userData.profileDiamond = userAction.iselite ? userAction.iselite : null,
      this.userData.profileUpgrade = userData.basics.probabilityToUpgrade ? userData.basics.probabilityToUpgrade : null,
      // tslint:disable-next-line: max-line-length
      this.userData.profileACProspect = userData.basics.probabilityToAssistedUser ? userData.basics.probabilityToAssistedUser.substring(0, 1).toUpperCase() + userData.basics.probabilityToAssistedUser.substring(1) : null
    this.profileActionBtn = false;
    // In case User click on Cancel in Profile Value form we have to bring it in previous form

    // this.sharedservice.profileValue.profileQuality = this.userProfileValueForm.get('profileQuality').value;
    // this.sharedservice.profileValue.profileDiamond = this.userProfileValueForm.get('profileDiamond').value;
    // this.sharedservice.profileValue.profileUpgrade = this.userProfileValueForm.get('profileUpgrade').value;
    // this.sharedservice.profileValue.profileACProspect = this.userProfileValueForm.get('profileACProspect').value;

    this.matchesIconArr[0].count = userAction.browsecount;
    // this.matchesIconArr[1].count = userAction.otherbrowsecount;
    this.matchesIconArr[1].count = userAction.holdmatchescount;
    this.matchesIconArr[2].count = userAction.totalMatchVeiwedByme;
    this.matchesIconArr[3].count = userAction.matchesSavedByMe;
    this.matchesIconArr[4].count = userAction.blockedcount;
    this.matchesIconArr[5].count = userAction.interestSent;
    this.matchesIconArr[6].count = userAction.MutualInterestCount;
    this.matchesIconArr[7].count = userAction.totalInterestReceived;
    this.matchesIconArr[8].count = userAction.interestDeclinedByMe;
    this.matchesIconArr[9].count = userAction.interestDeclined;
    this.matchesIconArr[10].count = userAction.superblockcount;
    
  }

  templateopen() {
    if (this.userType == 'supervisor' || this.userType == 'admin') {
      const dialog = this.dialog.open(EmailTemplateDialogComponent, {
        autoFocus: false,
        height: '90vh',
        width:'60vw',
        data: {userAlias: this.userData.alias, userID: this.userData.userId,
        userProfileImage: this.userData.profileImage, firstName: this.userData.userFirstName}
      });
      dialog.afterClosed().subscribe( res => {
       if(res) {
        // console.log('successful');
       }
      });
    } else {
      this.sharedservice.openSnackBar('This feature is not available for reviewer', 2000);
    }
   
  }

  detectURLs(notes) {
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return notes.match(urlRegex)
  }

  replaceURLs(notes) { //check URL inside Notes then hightlight the link.
    this.detectURLs(notes);
    if(!notes) return;
  
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return notes.replace(urlRegex, function (url) {
      let hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return  '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + ' </a>'
    });
  }

}
